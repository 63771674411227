/* A Global */
html,
body,
#root,
.App {
  height: 100%;
}

// h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover { text-decoration: none !important; }

 /* Borders */
.fcx-gray-divider { border: 1px solid #eee; }
.fcx-black-divider { border: 1px solid #000; }

// /* Border Radius */
// .fcx-round-5 { border-radius: 5px !important; }
// .fcx-round-5-bottom { border-radius: 0 0 5px 5px !important; }
// .fcx-round-10 { border-radius: 10px !important; }
// .fcx-round-20 { border-radius: 20px !important; }
// .fcx-round-30 { border-radius: 30px !important; }
// .fcx-round-40 { border-radius: 40px !important; }

 /* Display */
 .d-block        { display: block; }
 .d-inline-block { display: inline-block; }
 .d-flex         { display: flex; }

 /* DOM Position */
 .p-relative { position: relative !important; }
 .p-absolute { position: absolute !important; }

 /* Font Weights */
 .f-light            { font-weight: 300 !important; }
 .f-normal           { font-weight: 500 !important; }
 .f-bold             { font-weight: 700 !important; }
 .f-bold-black       { font-weight: 900 !important; }

 /* Heights & Widths */
 .h-100        { height: 100% !important; }
 .w-100        { width: 100% !important; }

 /* Margins and Paddings */
 .fcx-mb-none  { margin-bottom: 0 !important; }
 .fcx-mb-5     { margin-bottom: 0.5vw !important; }
 .fcx-mb-10    { margin-bottom: 1vw !important; }
 .fcx-mb-15    { margin-bottom: 1.5vw !important; }
 .fcx-mb-20    { margin-bottom: 2vw !important; }
 .fcx-mb-25    { margin-bottom: 2.5vw !important; }
 .fcx-mb-30    { margin-bottom: 3vw !important; }
 .fcx-mb-40    { margin-bottom: 4vw !important; }

 .fcx-mt-none  { margin-top: 0 !important; }
 .fcx-mt-5     { margin-top: 0.5vw !important; }
 .fcx-mt-10    { margin-top: 1vw !important; }
 .fcx-mt-15    { margin-top: 1.5vw !important; }
 .fcx-mt-20    { margin-top: 2vw !important; }
 .fcx-mt-25    { margin-top: 2.5vw !important; }
 .fcx-mt-30    { margin-top: 3vw !important; }
 .fcx-mt-40    { margin-top: 4vw !important; }

 .fcx-mr-none  { margin-right: 0 !important; }
 .fcx-mr-5     { margin-right: 0.5vw !important; }
 .fcx-mr-10    { margin-right: 1vw !important; }
 .fcx-mr-15    { margin-right: 1.5vw !important; }
 .fcx-mr-20    { margin-right: 2vw !important; }
 .fcx-mr-25    { margin-right: 2.5vw !important; }
 .fcx-mr-30    { margin-right: 3vw !important; }
 .fcx-mr-40    { margin-right: 4vw !important; }

 .fcx-ml-none  { margin-left: 0 !important; }
 .fcx-ml-5     { margin-left: 0.5vw !important; }
 .fcx-ml-10    { margin-left: 1vw !important; }
 .fcx-ml-15    { margin-left: 1.5vw !important; }
 .fcx-ml-20    { margin-left: 2vw !important; }
 .fcx-ml-25    { margin-left: 2.5vw !important; }
 .fcx-ml-30    { margin-left: 3vw !important; }
 .fcx-ml-40    { margin-left: 4vw !important; }

 .fcx-mx-none  { margin-left: 0 !important; margin-right: 0 !important; }
 .fcx-mx-5     { margin-left: 0.5vw !important; margin-right: 0.5vw !important; }
 .fcx-mx-10    { margin-left: 1vw !important; margin-right: 1vw !important; }
 .fcx-mx-15    { margin-left: 1.5vw !important; margin-right: 1.5vw !important; }
 .fcx-mx-20    { margin-left: 2vw !important; margin-right: 2vw !important; }
 .fcx-mx-25    { margin-left: 2.5vw !important; margin-right: 2.5vw !important; }
 .fcx-mx-30    { margin-left: 3vw !important; margin-right: 3vw !important; }
 .fcx-mx-40    { margin-left: 4vw !important; margin-right: 4vw !important; }

 .fcx-my-none  { margin-top: 0 !important; margin-bottom: 0 !important; }
 .fcx-my-5     { margin-top: 0.5vw !important; margin-bottom: 0.5vw !important; }
 .fcx-my-10    { margin-top: 1vw !important; margin-bottom: 1vw !important; }
 .fcx-my-15    { margin-top: 1.5vw !important; margin-bottom: 1.5vw !important; }
 .fcx-my-20    { margin-top: 2vw !important; margin-bottom: 2vw !important; }
 .fcx-my-25    { margin-top: 2.5vw !important; margin-bottom: 2.5vw !important; }
 .fcx-my-30    { margin-top: 3vw !important; margin-bottom: 3vw !important; }
 .fcx-my-40    { margin-top: 4vw !important; margin-bottom: 4vw !important; }

 .fcx-p-none   { padding: 0 !important; }
 .fcx-p-5      { padding: 0.5vw !important; }
 .fcx-p-10     { padding: 1vw !important; }
 .fcx-p-15     { padding: 1.5vw !important; }
 .fcx-p-20     { padding: 2vw !important; }
 .fcx-p-25     { padding: 2.5vw !important; }
 .fcx-p-30     { padding: 3vw !important; }
 .fcx-p-40     { padding: 4vw !important; }

 .fcx-pt-none  { padding-top: 0 !important; }
 .fcx-pt-5     { padding-top: 0.5vw !important; }
 .fcx-pt-10    { padding-top: 1vw !important; }
 .fcx-pt-15    { padding-top: 1.5vw !important; }
 .fcx-pt-20    { padding-top: 2vw !important; }
 .fcx-pt-25    { padding-top: 2.5vw !important; }
 .fcx-pt-30    { padding-top: 3vw !important; }
 .fcx-pt-40    { padding-top: 4vw !important; }

 .fcx-pr-none  { padding-right: 0 !important; }
 .fcx-pr-5     { padding-right: 0.5vw !important; }
 .fcx-pr-10    { padding-right: 1vw !important; }
 .fcx-pr-15    { padding-right: 1.5vw !important; }
 .fcx-pr-20    { padding-right: 2vw !important; }
 .fcx-pr-25    { padding-right: 2.5vw !important; }
 .fcx-pr-30    { padding-right: 3vw !important; }
 .fcx-pr-40    { padding-right: 4vw !important; }

 .fcx-pb-none  { padding-bottom: 0 !important; }
 .fcx-pb-5     { padding-bottom: 0.5vw !important; }
 .fcx-pb-10    { padding-bottom: 1vw !important; }
 .fcx-pb-15    { padding-bottom: 1.5vw !important; }
 .fcx-pb-20    { padding-bottom: 2vw !important; }
 .fcx-pb-25    { padding-bottom: 2.5vw !important; }
 .fcx-pb-30    { padding-bottom: 3vw !important; }
 .fcx-pb-40    { padding-bottom: 4vw !important; }

 .fcx-pl-none  { padding-left: 0 !important; }
 .fcx-pl-5     { padding-left: 0.5vw !important; }
 .fcx-pl-10    { padding-left: 1vw !important; }
 .fcx-pl-15    { padding-left: 1.5vw !important; }
 .fcx-pl-20    { padding-left: 2vw !important; }
 .fcx-pl-25    { padding-left: 2.5vw !important; }
 .fcx-pl-30    { padding-left: 3vw !important; }
 .fcx-pl-40    { padding-left: 4vw !important; }

 .fcx-px-none  { padding-left: 0 !important; padding-right: 0 !important; }
 .fcx-px-5     { padding-left: 0.5vw !important; padding-right: 0.5vw !important; }
 .fcx-px-10    { padding-left: 1vw !important; padding-right: 1vw !important; }
 .fcx-px-15    { padding-left: 1.5vw !important; padding-right: 1.5vw !important; }
 .fcx-px-20    { padding-left: 2vw !important; padding-right: 2vw !important; }
 .fcx-px-25    { padding-left: 2.5vw !important; padding-right: 2.5vw !important; }
 .fcx-px-30    { padding-left: 3vw !important; padding-right: 3vw !important; }
 .fcx-px-40    { padding-left: 4vw !important; padding-right: 4vw !important; }

 .fcx-py-none  { padding-top: 0 !important; padding-bottom: 0 !important; }
 .fcx-py-5     { padding-top: 0.5vw !important; padding-bottom: 0.5vw !important; }
 .fcx-py-10    { padding-top: 1vw !important; padding-bottom: 1vw !important; }
 .fcx-py-15    { padding-top: 1.5vw !important; padding-bottom: 1.5vw !important; }
 .fcx-py-20    { padding-top: 2vw !important; padding-bottom: 2vw !important; }
 .fcx-py-25    { padding-top: 2.5vw !important; padding-bottom: 2.5vw !important; }
 .fcx-py-30    { padding-top: 3vw !important; padding-bottom: 3vw !important; }
 .fcx-py-40    { padding-top: 4vw !important; padding-bottom: 4vw !important; }

// /* Links */
// .fcx-underline-none:hover { text-decoration: none !important; }
// .fcx-underline:hover { text-decoration: underline !important; }

 /* Text Alignment */
 .p-align-left             { text-align: left !important; }
 .p-align-left-2-right     { text-align: left !important; }
 .p-align-left-2-center    { text-align: left !important; }
 .p-align-right            { text-align: right !important; }
 .p-align-right-2-left     { text-align: right !important; }
 .p-align-right-2-center   { text-align: right !important; }
 .p-align-center           { text-align: center !important; }
 .p-align-center-2-left    { text-align: center !important; }
 .p-align-center-2-right   { text-align: center !important; }

 @media (max-width: 959px) {
     .p-align-left-2-right   { text-align: right !important; }
     .p-align-left-2-center  { text-align: center !important; }
     .p-align-right-2-left   { text-align: left !important; }
     .p-align-right-2-center { text-align: center !important; }a
     .p-align-center-2-left  { text-align: left !important; }
     .p-align-center-2-right { text-align: right !important; }
 }

 /* Text */
 .f-break-word { word-wrap: break-word; }
 .f-uppercase { text-transform: uppercase; }

 /* Shifting */
 .fcx-shift-up-10 { position: relative; top: -10px; margin-bottom: -10px; }
 .fcx-shift-up-20 { position: relative; top: -20px; margin-bottom: -20px; }
 .fcx-shift-up-30 { position: relative; top: -30px; margin-bottom: -30px; }
 .fcx-shift-up-40 { position: relative; top: -40px; margin-bottom: -40px; }
 .fcx-shift-up-50 { position: relative; top: -50px; margin-bottom: -50px; }
 .fcx-shift-up-80 { position: relative; top: -80px; margin-bottom: -80px; }

@media (max-width: 759px) {
 .fcx-shift-down-15 { position: relative; top: 15px; }
}

// /* Z-index */
// .fcx-z-0    { z-index: 0 !important; }
// .fcx-z-10   { z-index: 10 !important; }

.fcx-ol-list { padding-left: 15px; line-height: 2;}
.fcx-blue-box { background: #ecf3f9; height: 100%; border-radius: 10px;}
.fcx-gray-box { background: #F2F3F4; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; border-radius: 10px; }

/* JODIT STYLING */
.jodit-container:not(.jodit_inline) {
 border: 1px solid #e3e8f2 !important;
 border-radius: 8px !important;
}
.jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal {
 background-image: repeating-linear-gradient(#f4f5f9 0,#f4f5f9 37px,#f4f5f9 38px) !important;
}
.jodit-toolbar__box:not(:empty) {
 background-color: #f4f5f8  !important;
 border-bottom: 1px solid #e9edf4  !important;
 border-radius: 8px 8px 0 0  !important;
}
.jodit-workplace+.jodit-status-bar:not(:empty) {
 border-top: 1px solid #f0f3f8;
}
.jodit-status-bar {
 background-color: #f4f5f9 !important;
 border-radius: 0 0 8px 8px !important;
}